<!--  -->
<template>
  <div class="box">
    <el-card class="box_body" v-if="type == 1">
      <div class="search">
        <span>筛选学科</span>
        <el-select
          v-model="subjectValue"
          placeholder="全部"
          multiple
          collapse-tags
          size="mini"
        >
          <el-option
            v-for="item in subjectOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <div class="join-button">
          <el-button size="mini" type="primary" @click="headOperation(0)"
            >搜索</el-button
          >
          <el-button size="mini" @click="headOperation(1)">重置</el-button>
          <el-button
            type="primary"
            size="mini"
            class="add-button"
            @click="treeOperation(null, 5)"
            >添加根节点</el-button
          >
        </div>
      </div>
      <el-scrollbar>
        <el-card class="card_body">
          <el-empty
            description="暂无数据"
            v-if="treeList.length == 0"
          ></el-empty>
          <el-table
            :data="treeList"
            style="width: 100%; margin-bottom: 20px"
            v-if="treeList.length !== 0"
            :header-cell-style="{
              background: '#F3F7FF',
              color: '#0B1B31',
              fontSize: '14px',
              fontWeight: '500',
            }"
          >
            <el-table-column type="expand">
              <template slot-scope="scope">
                <el-table
                  class="table_calss"
                  :data="scope.row.children"
                  style="width: 100%; margin-bottom: 20px"
                  row-key="id"
                  :tree-props="{
                    children: 'children',
                    hasChildren: 'hasChildren',
                  }"
                  :header-cell-style="{
                    background: '#F5F7FA',
                    color: '#0B1B31',
                    fontSize: '14px',
                    fontWeight: '500',
                  }"
                >
                  <el-table-column prop="id" label="专题ID"></el-table-column>
                  <el-table-column
                    prop="title"
                    label="专题名称"
                  ></el-table-column>
                  <el-table-column
                    prop="description"
                    label="描述"
                    :show-overflow-tooltip="true"
                  >
                    <template slot-scope="scope">
                      <span v-html="scope.row.description"></span>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作">
                    <template slot-scope="scope">
                      <el-dropdown
                        @command="treeOperation(scope.row, $event)"
                        v-if="scope.row.publishState != 1"
                      >
                        <span class="el-dropdown-link">
                          添加
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item size="mini" :command="0"
                            >子节点</el-dropdown-item
                          >
                          <el-dropdown-item :command="7"
                            >知识点</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                      <el-dropdown @command="treeOperation(scope.row, $event)">
                        <span class="el-dropdown-link">
                          编辑
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item :command="1"
                            >该节点</el-dropdown-item
                          >
                          <el-dropdown-item :command="8"
                            >知识点</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                      <el-dropdown
                        @command="treeOperation(scope.row, $event)"
                        v-if="scope.row.publishState != 1"
                      >
                        <span class="el-dropdown-link">
                          删除
                          <i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item :command="2"
                            >子节点</el-dropdown-item
                          >
                          <el-dropdown-item :command="8"
                            >知识点</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </el-dropdown>
                      <el-button
                        type="text"
                        size="mini"
                        @click.stop="treeOperation(scope.row, 3)"
                        :disabled="scope.row.publishState == 1"
                        >发布</el-button
                      >
                      <el-button
                        type="text"
                        size="mini"
                        @click.stop="treeOperation(scope.row, 4)"
                        >进入</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column prop="id" label="课程ID"></el-table-column>
            <el-table-column
              prop="subjectName"
              label="课程名称"
            ></el-table-column>
            <el-table-column prop="remark" label="课程描述"></el-table-column>
          </el-table>
          <el-pagination
            v-if="treeList.length !== 0"
            background
            layout="prev, pager, next, sizes, jumper"
            :total="page.total"
            :current-page="page.current"
            :page-size="page.pagesize"
            :page-sizes="[10, 20, 30, 40, 50]"
            @current-change="collapseData"
            @size-change="handleSizeChange"
          ></el-pagination>
        </el-card>
      </el-scrollbar>
    </el-card>
    <tree-details
      v-if="type == 2"
      @go_back="go_back"
      :treeData="treeData"
      @del_knowledge="del_knowledge"
      @go_question="go_question"
      :del_fin="del_fin"
      @caseData="caseData_details"
    ></tree-details>
    <!-- <add-variant v-if="type == 3" @go_back="go_back" :treeData="treeData" :editId="editId"></add-variant> -->
    <dialogTree
      :showDialog="showDialog"
      :subjectOptions="subjectOptions"
      @number="show_number"
      :treeNode="treeNode"
    ></dialogTree>
    <dialogTable
      :showTable="showTable"
      @unshow="showTable = false"
      @go_question="go_question"
      @del_knowledge="del_knowledge"
      :treeData="treeData"
      :del_fin="del_fin"
    ></dialogTable>
    <importQuestion
      v-show="type == 4"
      @go_back="importQ_back"
      :type="page_type"
      :treeData="treeData"
      :page_know="page_know"
    ></importQuestion>
    <Add
      v-if="type == 5"
      @go_back="go_back"
      :treeData="treeData"
      :caseData="caseData"
    ></Add>
  </div>
</template>

<script>
import dialogTree from "./components/dialogTree.vue";
import treeDetails from "./components/treeDetails.vue";
import Add from "./components/Add.vue";
import dialogTable from "./components/dialogTable.vue";
import importQuestion from "./components/importQuestion.vue";
import {
  subjectList,
  themeGetThemeTreePage,
  themeDelete,
  themePublish,
  knowledgeDelete,
} from "../../../api/api";
export default {
  data() {
    return {
      page: {
        total: 0,
        current: 1,
        pagesize: 10,
      }, //分页
      type: 1, //类型 1 列表  2 详情  3 新增变式  4 添加知识点
      page_type: -1, //进入知识点页面  1 添加知识点  0 编辑知识点
      subjectValue: [], //搜索学科内容
      subjectOptions: [], //学科数组
      treeList: [], //树状图数组
      activeNames: [1], //折叠面板打开的部分
      showDialog: -1, //弹框显示隐藏
      defaultProps: {
        //指定树状图结构
        children: "children",
        label: "title",
      },
      treeData: null, //进入的id
      treeNode: {}, //点击的节点
      showTable: false, //知识点表格
      edit: "",
      page_know: {},
      del_fin: 0,
      caseData: {
        id: 5,
        caseName: "3变式标题",
      },
      expand_arr: [],
    };
  },
  components: {
    dialogTree,
    treeDetails,
    Add,
    dialogTable,
    importQuestion,
  },
  mounted() {
    this.loadSubjectList();
  },
  methods: {
    caseData_details(val) {
      this.caseData = val;
    },
    // 前往编辑知识点
    go_question(val) {
      this.showTable = false;
      this.type = 4;
      this.page_type = 0;
      this.page_know = val;
    },
    // 添加或编辑知识点返回
    importQ_back(val) {
      this.page_type = -1;
      if (val == 1) {
        this.type = val;
      } else if (val == 0) {
        this.type = 2;
      }
    },
    // 删除知识点
    del_knowledge(val) {
      this.dialog_confirm("永久删除该知识点", 2, val);
    },
    // 加载节点树
    loadThemeTree(obj) {
      let param = {
        query: obj ? obj : null,
        // "subjectId": 131
        pageNum: this.page.current,
        pageSize: this.page.pagesize,
      };
      themeGetThemeTreePage(param).then((res) => {
        if (res.code == 0) {
          res.data.list.map((item) => {
            item.id = item.subjectId;
            item.children = item.themeTree;
          });
          this.treeList = res.data.list;
          this.page.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    show_number(val) {
      this.showDialog = val;
      if (val == -2) {
        if (this.subjectValue.length > 0) {
          let obj = {
            subjectIds: this.subjectValue,
          };
          this.loadThemeTree(obj);
        } else {
          this.loadThemeTree();
        }
      }
    },
    // 加载学科列表
    loadSubjectList() {
      let param = {
        pageNum: null,
        pageSize: null,
        name: null,
      };
      subjectList(param).then((res) => {
        if (res.code == 0) {
          this.subjectOptions = res.data.list;
          this.loadThemeTree();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // // 加载专题列表
    // loadTree (obj) {
    //   let param = {
    //     pageNum: this.page.current,
    //     pageSize: this.page.pagesize,
    //     name: obj && obj.id !== null ? obj.name : null
    //   }
    //   subjectList(param).then(res => {
    //     if (res.code == 0) {
    //       this.page.total = res.data.total
    //       this.treeList = res.data.list
    //       this.loadThemeTree()
    //     } else {
    //       this.$message.error(res.message)
    //     }
    //   })
    // },
    // 组件跳转
    go_back(val) {
      this.type = val;
      if (this.subjectValue.length > 0) {
        let obj = {
          subjectIds: this.subjectValue,
        };
        this.loadThemeTree(obj);
      } else {
        this.loadThemeTree();
      }
    },
    // 树状图点击事件
    treeOperation(data, type) {
      // type 0 添加子节点  1 修改  2 删除  3 发布  4 进入  5 添加根节点  6 编辑知识点(详情页)  7 添加知识点  8 编辑知识点
      this.showDialog = type;
      if (type == 0) {
        this.treeNode = data;
      } else if (type == 1) {
        this.treeNode = data;
      } else if (type == 2) {
        this.dialog_confirm("永久删除该节点", 0, data.id);
      } else if (type == 3) {
        this.dialog_confirm("发布该节点", 1, data.id);
      } else if (type == 4) {
        this.type = 2;
        this.treeData = data;
        // console.log(this.treeData)
      } else if (type == 7) {
        this.type = 4;
        this.page_type = 1;
        this.treeData = data;
      } else if (type == 8) {
        this.showTable = true;
        this.treeData = data;
      }
    },
    // 导航栏点击事件
    headOperation(type) {
      // type  0 搜索  1 重置
      if (type == 0) {
        if (this.subjectValue.length > 0) {
          let obj = {
            subjectIds: this.subjectValue,
          };
          this.page.current = 1;
          this.loadThemeTree(obj);
        }
      } else if (type == 1) {
        this.subjectValue = [];
        this.loadThemeTree();
      }
    },
    // 分页
    collapseData(val) {
      this.page.current = val;
      if (this.subjectValue.length > 0) {
        let obj = {
          subjectIds: this.subjectValue,
        };
        this.loadThemeTree(obj);
      } else {
        this.loadThemeTree();
      }
    },
    // 选择分页
    handleSizeChange(val) {
      this.page.pagesize = val;
      if (this.subjectValue.length > 0) {
        let obj = {
          subjectIds: this.subjectValue,
        };
        this.loadThemeTree(obj);
      } else {
        this.loadThemeTree();
      }
    },
    dialog_confirm(val, type, id) {
      // type  0 删除节点  1 发布节点  2 删除知识点
      this.$confirm("此操作将" + val + ", 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (type == 0) {
            themeDelete({ id: id }).then((res) => {
              if (res.code == 0) {
                if (this.subjectValue.length > 0) {
                  let obj = {
                    subjectIds: this.subjectValue,
                  };
                  this.loadThemeTree(obj);
                } else {
                  this.loadThemeTree();
                }
                this.$message({
                  type: "success",
                  message: "删除节点成功!",
                });
              } else {
                this.$message.error(res.message);
              }
            });
          } else if (type == 1) {
            themePublish({ id: id }).then((res) => {
              if (res.code == 0) {
                if (this.subjectValue.length > 0) {
                  let obj = {
                    subjectIds: this.subjectValue,
                  };
                  this.loadThemeTree(obj);
                } else {
                  this.loadThemeTree();
                }
                this.$message({
                  type: "success",
                  message: "发布节点成功!",
                });
              } else {
                this.$message.error(res.message);
              }
            });
          } else if (type == 2) {
            knowledgeDelete({ id: id }).then((res) => {
              if (res.code == 0) {
                this.del_fin = 1;
                this.$message({
                  type: "success",
                  message: "删除知识点成功!",
                });
              } else {
                this.$message.error(res.message);
              }
            });
            this.del_fin = 0;
          }
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang='less' scoped>
@import "./style/index.less";
/deep/.el-scrollbar {
  height: calc(100% - 50px);
}
/deep/.el-dropdown {
  margin-right: 10px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
  font-size: 12px;
}
.el-icon-arrow-down {
  font-size: 12px;
}
/deep/.el-table__expanded-cell {
  padding: 0;
}
</style>
