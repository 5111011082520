<template>
  <div class="box">
    <el-card class="box-card">
      <div class="variantDescription" style="height: 100%">
        <!-- 描述 -->
        <div v-if="isvariant == 1">
          <span>描述类型:</span>
          <el-select
            style="margin-left: 10px"
            v-model="form.variantDescriptionValue"
            placeholder="变式描述"
            size="mini"
            :disabled="ShowEdit"
          >
            <el-option
              v-for="item in variantDescription"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-button
            type="danger"
            @click="removeSaveDescription"
            style="float: right"
            v-if="!(isEdit == 0) && !ShowEdit"
          >删除描述</el-button>
          <el-button type="success" @click="saveDescription" style="margin-left: 20px">保存描述</el-button>
          <div class="richText">
            <mceEditor v-if="IScaseTopic" v-model="form.description" ref="addEditorSpace" />
          </div>
        </div>
        <!-- 题目 -->
        <div v-if="isvariant == 2" style="height: 100%">
          <el-scrollbar>
            <el-form label-position="right" label-width="100px" :model="form" pro>
              <el-form-item label="题目名称：">
                <el-input
                  size="mini"
                  placeholder="请输入题目名称"
                  v-model="form.caseTopicSerial"
                  style="width: 300px"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="题目类型:">
                <el-select
                  style
                  v-model="form.variantSubjectValue"
                  placeholder="类型"
                  size="mini"
                  :disabled="ShowEdit"
                >
                  <el-option
                    v-for="item in variantSubjectData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="题干：">
                <mceEditor v-model="form.caseTopic" ref="addEditorCaseTopic" :height="400" />
              </el-form-item>
              <el-form-item label="选项：">
                <el-button type="success" @click="addTopic" size="medium">添加选项</el-button>
                <el-main style="height: 300px">
                  <el-table :data="topicData" style="width: 100%">
                    <el-table-column label="序号" width="50" align="center">
                      <template slot-scope="{ row }">{{ row.sn }}</template>
                    </el-table-column>
                    <el-table-column label="内容" width="300" show-overflow-tooltip align="center">
                      <template slot-scope="{ row }">
                        <div v-html="row.choiceContent"></div>
                      </template>
                    </el-table-column>
                    <el-table-column label="正确答案" width="110" align="center">
                      <template slot-scope="{ row }">
                        <!-- {{ row.isAnswer == 0 ? "否" : "是" }} -->
                        <el-select
                          size="mini"
                          v-model="row.isAnswer"
                          placeholder="请选择"
                          style="text-align: center; font-size: 14px"
                        >
                          <el-option
                            v-for="item in IsAnswers"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          ></el-option>
                        </el-select>
                      </template>
                    </el-table-column>
                    <el-table-column label="错误原因" width="180" show-overflow-tooltip align="center">
                      <template slot-scope="{ row }">
                        <div v-html="row.cause"></div>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="300">
                      <template slot-scope="scope">
                        <el-button
                          size="mini"
                          type="danger"
                          style="margin-left: 10px"
                          @click="handleDelete(scope.$index, scope.row)"
                        >删除</el-button>
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                        <el-button size="mini" @click="optionMove(scope.$index, scope.row, 'up')">上移</el-button>
                        <el-button
                          size="mini"
                          @click="optionMove(scope.$index, scope.row, 'down')"
                        >下移</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-main>
              </el-form-item>
              <el-form-item label="解析：">
                <mceEditor
                  id="addEditorCaseAnalysis"
                  v-model="form.caseAnalysis"
                  ref="addEditorCaseAnalysis"
                  :height="400"
                />
              </el-form-item>
              <el-form-item label="难度：">
                <el-rate style v-model="form.difficulty" class="FormDifficult"></el-rate>
              </el-form-item>
              <el-form-item label="标记：">
                <el-radio v-model="form.caseSign" label="1">真题</el-radio>
                <el-radio v-model="form.caseSign" label="2">练习题</el-radio>
              </el-form-item>
              <el-form-item>
                <el-button type="success" @click="saveDescription">保存题目</el-button>
                <el-button type="danger" @click="removeSaveDescription">删除题目</el-button>
              </el-form-item>
            </el-form>
          </el-scrollbar>
        </div>
      </div>
      <!-- 树 -->
      <div class="tree">
        <p>{{ caseName }}</p>
        <el-button
          type="primary"
          @click="append('变式描述')"
          v-if="!ShowEdit"
          style="margin-bottom: 15px; margin-left: 15px; margin-top: 15px"
          size="small"
        >新增描述</el-button>
        <el-button
          type="primary"
          @click="append('变式题目集')"
          size="small"
          v-if="!ShowEdit"
          style="margin-bottom: 15px; margin-left: 15px"
        >新增题目</el-button>
        <el-main class="box-main">
          <el-tree
            style="font-size: 20px; background-color: rgb(240, 240, 240)"
            :data="data"
            v-if="Istree"
            node-key="id"
            default-expand-all
            @node-drop="handleDrop"
            draggable
            @node-click="nodeClick"
            :allow-drop="allowDrop"
            :allow-drag="allowDrag"
          >
            <!-- <span class="custom-tree-node" slot-scope="{ node, data }">
            <span
              >{{ node.label }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
            >
            <span v-if="node.childNodes.length !== 0">
              <el-button
                type="text"
                size="mini"
                @click.stop="() => append(node, data)"
                >添加子菜单</el-button
              >
            </span>
            </span>-->
          </el-tree>
        </el-main>
        <el-main>
          <div style="height: 70px"></div>
          <div class="but-bottom">
            <el-button
              type="warning"
              @click="clickPreview"
              size="small"
              style="margin-left: 10px;margin-bottom:10px"
            >预览变式</el-button>
            <el-button
              type="success"
              @click="clickSubmit"
              style="margin-bottom:10px"
              size="small"
            >提交变式</el-button>
            <el-button type="danger" @click="clickReturn" style="margin-bottom:10px" size="small">返回</el-button>
          </div>
        </el-main>
      </div>
    </el-card>
    <!-- //编辑弹窗 -->

    <el-dialog
      v-if="dialogVisible"
      title="提示"
      :visible.sync="dialogVisible"
      width="70%"
      :before-close="handleClose"
      :modal-append-to-body="false"
    >
      <el-scrollbar style="height:100%">
        <el-form label-position="right" label-width="100px" :model="topicDataContent">
          <el-form-item label="序号：">
            <el-input v-model="topicDataContent.sn"></el-input>
          </el-form-item>
          <el-form-item label="内容：">
            <mceEditor
              v-if="IScaseTopic"
              v-model="topicDataContent.choiceContent"
              ref="addEditorSpaceChoiceContent"
              :height="400"
            />
          </el-form-item>
          <el-form-item label="错误原因：">
            <mceEditor
              v-if="IScaseTopic"
              v-model="topicDataContent.cause"
              ref="addEditorSpaceCause"
              :height="400"
            />
          </el-form-item>
        </el-form>
      </el-scrollbar>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogConfirm(1)">取 消</el-button>
        <el-button type="primary" @click="dialogConfirm()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import mceEditor from "../../../materialGather/k12SetQuestion/mceEditor.vue";
import {
  themeCaseAddCaseDescribe,
  themeCaseDetail,
  getSpecialtopic,
} from "../../../../api/api";
import store from "../../../../store/index";
import { mapGetters } from "vuex";

export default {
  props: {
    treeData: {
      type: Object,
    },

    caseData: {
      type: Object,
    },
  },
  data () {
    return {
      num: 1,

      // 变式描述
      variantDescription: [
        {
          value: "1",
          label: "掌握要求",
        },
        {
          value: "2",
          label: "考试要求",
        },
        {
          value: "3",
          label: "知识点名称",
        },
        {
          value: "4",
          label: "重要知识",
        },
        {
          value: "5",
          label: "常见错误",
        },
        {
          value: "6",
          label: "题目内容",
        },
        {
          value: "7",
          label: "题目特点",
        },
        {
          value: "8",
          label: "难易程度",
        },
        {
          value: "9",
          label: "适用对象",
        },
      ],
      //变式题目
      variantSubjectData: [
        {
          value: "1",
          label: "单选题",
        },
        {
          value: "2",
          label: "多选题",
        },
      ],
      //树状图
      data: [
        {
          id: 1,
          label: "变式描述",
          children: [],
        },
        {
          id: 2,
          label: "变式题目集",
          children: [],
        },
      ],
      //选项
      topicData: [],
      //选项细节描述
      topicDataContent: {
        choiceContent: "",
        cause: "",
        sn: "",
      },
      isEdit: 0, //是否编辑 1编辑
      isvariant: 0, //控制 1描述 2变式
      //控制是否开启拖拽
      isdraggable: false,
      // 一级菜单修改one 二级菜单修改two
      isOpen: "two",
      id: 100,
      // 右侧表单内容
      form: {
        variantSubjectValue: "1", //题目类型--//1单选2多选
        variantDescriptionValue: "1",
        caseTopicSerial: "", //变式编号
        id: null,
        label: "",
        value: "",
        description: "",
        stemContent: "",
        difficulty: 1,
        score: 1,
        caseSign: "1", //真题还练习题
        caseTopic: " ", //题干
        caseAnalysis: " ", //解析
      },
      IsAnswers: [
        { value: 0, label: "否" },
        { value: 1, label: "是" },
      ],
      dialogVisible: false,
      Ascll: 65,
      topicIndex: null, //题目编辑的下标
      Istree: true, //控制树状图显示不
      specialTopicId: "",
      sn: "11", //组号
      type: 4, //组号里的序号
      ShowEdit: false, //判断是否是编辑无法修改
      caseID: "",
      caseName: "",
      Treeid: 1,
      Treepreview: null,
      IScaseTopic: true,
    };
  },
  watch: {
    "form.caseAnalysis": {
      handler (newValue, oldValue) {
        console.log('oldValue: ', oldValue);
        console.log('newValue: ', newValue);

      },
    },
    "caseAnalysis": {
      handler (newValue, oldValue) {
        console.log('oldValue: ', oldValue);
        console.log('newValue: ', newValue);

      },
    },
  },
  components: {
    mceEditor,
  },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters([
      "Variantid",
      // ...
    ]),
  },
  mounted () {
    this.init();
    this.verification();
  },
  created () { },
  methods: {
    //初始化
    //防止token过期
    verification () {
      var param = {
        grade: 1,
        subject: 1,
        specialtopicType: 0,
      };
      setInterval(() => {
        getSpecialtopic(param).then((res) => { });
      }, 60000);
    },
    init () {
      this.caseID = this.caseData.id;
      this.caseName = this.caseData.caseName;
      themeCaseDetail({
        id: this.caseID,
      }).then((res) => {
        var tree = res.data.themeCaseNameEntity.knowledgeId.split(",");
        this.Treepreview = tree[1];
        res.data.describeDO.map((item, index) => {
          this.variantDescription.map((name) => {
            if (item.type == name.value) {
              item["label"] = name.label;
            }
            item["description"] = item.nameDescribe;
            item["variantDescriptionValue"] = String(item.type);
          });
        });
        this.data[0].children = res.data.describeDO;
        // //题目
        res.data.caseDO.sort((a, b) => {
          return +a.displaySN < +b.displaySN ? "-1" : "1";
        });
        res.data.caseDO.map((item, index) => {
          item["label"] = item.caseTopicSerial || "我是编号";
          item["list"] = item.answerEntities;
          item["difficulty"] = item.caseDifficulty;
          item["variantSubjectValue"] = String(item.caseType);
          item["caseSign"] = String(item.caseSign);
        });

        this.data[1].children = res.data.caseDO;
        if (
          this.data[0].children.length == 0 &&
          this.data[1].children.length == 0
        ) {
          this.Istree = false;
        }
        // if (
        //   res.data.descriptionsEntities.length <= 0 ||
        //   res.data.caseDO <= 0
        // ) {
        // } else {
        //   this.submitData();
        // }
      });
    },
    submitData (type) {
      var describeDO = []; //描述
      this.data[0].children.map((item) => {
        //描述
        describeDO.push({
          caseNameId: this.caseID,
          nameDescribe: item.description,
          type: item.variantDescriptionValue,
        });
      });
      var caseDO = []; //题目
      this.data[1].children.map((item, index) => {
        //变式题目
        var list = []; //选项
        // var num = 0; //正确数字
        item.list.map((lists, index) => {
          list.push({
            isAnswer: lists.isAnswer,
            choiceContent: lists.choiceContent,
            sn: lists.sn,
            cause: lists.cause,
            // ChoiceValue: Math.pow(2, index), //每个选项加自己代表数字
          });
        });
        caseDO.push({
          caseTopicSerial: item.caseTopicSerial,
          caseNameId: this.caseID, //变式id
          caseTopic: item.caseTopic, //题目题干
          caseSign: item.caseSign, //真题还是练习题  1是真题，2是练习题
          caseAnalysis: item.caseAnalysis, //解析
          caseDifficulty: item.difficulty, //难度
          caseType: item.variantSubjectValue, //题目类型  类型 1单选 2多选
          answerEntities: list,
          // answer: num, //代表正确数字
        });
      });

      var obj = {
        id: this.caseID,
        caseDO: caseDO,
        describeDO: describeDO,
      };
      if (describeDO.length == 0 || caseDO.length == 0) {
        this.$message.error("变式或者题目为空，无法提交");
      } else {
        themeCaseAddCaseDescribe(obj).then((res) => {
          if (res.message == "请求成功") {
            this.$message({
              message: "保存变式成功,1秒后自动返回...",
              type: "success",
            });
            setTimeout(() => {
              this.$emit("go_back", 2);
            }, 1000);
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    //保存描述
    saveDescription () {
      if (this.isvariant == 2) {
        if (this.isOptionNull()) {
          setInterval(() => {
            this.IScaseTopic = true;
          }, 200);
          return;
        }
        if (this.isEdit == 1) {
          //编辑
          this.data[1].children.map((item, index) => {
            if (item.id == this.form.id) {
              this.data[1].children[index].stemContent = this.form.caseTopic;
              this.data[1].children[index].caseTopic = this.form.caseTopic;
              this.data[1].children[index].label = this.form.caseTopicSerial;
              this.data[1].children[index].sn = this.form.caseTopicSerial;
              this.data[1].children[index].list = this.topicData;
              this.data[1].children[index].type = this.form.variantSubjectValue;
              if (this.Istree == false) {
                this.Istree = true;
              }
              this.$message({
                message: "编辑成功",
                type: "success",
              });
              setInterval(() => {
                this.IScaseTopic = true;
              }, 200);
            }
          });
        } else {
          //新增
          var obj = JSON.parse(JSON.stringify(this.form));
          this.Treeid += 1;
          obj["label"] = this.form.caseTopicSerial;
          obj["list"] = this.topicData;
          obj["sn"] = this.form.caseTopicSerial;
          obj["type"] = this.form.variantSubjectValue;
          obj["id"] = this.Treeid + "tree";
          this.data[1].children.push(obj);
          if (this.Istree == false) {
            this.Istree = true;
          }
          this.$message({
            message: "添加成功",
            type: "success",
          });
          //添加成功后清空
          this.Ascll = 65;
          var obj = {
            variantDescriptionValue: "1",
            caseTopicSerial: "", //变式编号
            sn: "",
            id: null,
            label: "",
            value: "",
            description: "",
            stemContent: "",
            variantSubjectValue: "1", //题目类型--//1单选2多选
            difficulty: 1,
            score: 1,
            caseSign: "1", //真题还练习题
            caseTopic: "", //题干
            caseAnalysis: this.form.caseAnalysis, //解析
          };
          this.form = obj;
          this.topicData = [
            {
              sn: String.fromCharCode(this.Ascll++),
              choiceContent: null,
              isAnswer: 0,
              cause: null,
            },
            {
              sn: String.fromCharCode(this.Ascll++),
              choiceContent: null,
              isAnswer: 0,
              cause: null,
            },
            {
              sn: String.fromCharCode(this.Ascll++),
              choiceContent: null,
              isAnswer: 0,
              cause: null,
            },
            {
              sn: String.fromCharCode(this.Ascll++),
              choiceContent: null,
              isAnswer: 0,
              cause: null,
            },
          ];
          setInterval(() => {
            this.IScaseTopic = true;
          }, 200);
        }
      } else if (this.isvariant == 1) {
        if (this.isEdit == 1) {
          if (this.form.variantDescriptionValue == "") {
            this.$message.error("请选择描述类型");
          } else {
            this.data[0].children.map((item, index) => {
              if (item.id == this.form.id) {
                //接下来拿选项以及内容
                this.variantDescription.map((content) => {
                  if (content.value == this.form.variantDescriptionValue) {
                    this.data[0].children[index].descriptionType =
                      this.form.variantDescriptionValue;
                    this.data[0].children[index].label = content.label;
                    this.data[0].children[index].description =
                      this.form.description;
                    if (this.Istree == false) {
                      this.Istree = true;
                    }
                    this.$message({
                      message: "编辑成功",
                      type: "success",
                    });
                    setInterval(() => {
                      this.IScaseTopic = true;
                    }, 200);
                  }
                });
              }
            });
          }
        } else {
          if (this.form.variantDescriptionValue == "") {
            this.$message.error("请选择描述类型");
          } else if (this.form.description == "") {
            this.$message.error("请输入描述内容");
          } else {
            this.variantDescription.map((item) => {
              if (item.value == this.form.variantDescriptionValue) {
                var name = item.label;
                var obj = {
                  id: this.id++,
                  label: name,
                  variantDescriptionValue: this.form.variantDescriptionValue,
                  description: this.form.description,
                };
                this.data[0].children.push(obj);
                if (this.Istree == false) {
                  this.Istree = true;
                }
                this.$message({
                  message: "添加成功",
                  type: "success",
                });

                this.form = {
                  variantDescriptionValue: "1",
                  caseTopicSerial: "", //变式编号
                  id: null,
                  label: "",
                  value: "",
                  description: "",
                  stemContent: "",
                  variantSubjectValue: "1", //题目类型--//1单选2多选
                  difficulty: 1,
                  score: 1,
                  caseSign: "1", //真题还练习题
                  caseTopic: "", //题干
                  caseAnalysis: this.form.caseAnalysis, //解析
                };
                setInterval(() => {
                  this.IScaseTopic = true;
                }, 200);
              }
            });
          }
        }
      }
    },
    //点击树状图编辑
    nodeClick (data, b, c) {
      this.isEdit = 1; //开启编辑开关

      this.data.map((item, index) => {
        item.children.map((con) => {
          if (con.label == data.label) {
            if (index == 0) {
              //0为描述
              this.form = data; //内容

              this.isvariant = 1;
            } else if (index == 1) {
              //1为题目
              console.log('data', data);
              this.form = data;
              //this.form["caseAnalysis"] = "wxafad";     
              this.form["caseAnalysis"] = JSON.parse(JSON.stringify(data.caseAnalysis));
              this.form["caseTopicSerial"] = data.label;
              this.form["variantDescriptionValue"] = data.type;
              this.isvariant = 2;
              var topicData = [];
              if (data.list.length > 0) {
                data.list.sort((a, b) => {
                  return +a.sn.charCodeAt() < +b.sn.charCodeAt() ? "-1" : "1";
                });
                topicData = JSON.parse(JSON.stringify(data.list));
              }

              this.topicData = topicData;
              var Ascll = this.topicData[this.topicData.length - 1];
              if (Ascll.sn.charCodeAt() >= 65 && Ascll.sn.charCodeAt() <= 90) {
                this.Ascll = Ascll.sn.charCodeAt() + 1;
              }
            }
          }
        });
      });
    },
    //新增描述和题目
    append (data) {
      this.isEdit = 0; //关闭
      this.Ascll = 65;
      if (data == "变式题目集") {
        this.isvariant = 2;
        this.form = {
          variantDescriptionValue: "1",
          caseTopicSerial: "", //变式编号
          id: null,
          label: "",
          value: "",
          description: "",
          stemContent: "",
          variantSubjectValue: "1", //题目类型--//1单选2多选
          difficulty: 1,
          score: 1,
          caseSign: "1", //真题还练习题
          caseTopic: "", //题干
          caseAnalysis: this.form.caseAnalysis, //解析
        };
        //添加选项
        this.topicData = [
          {
            sn: String.fromCharCode(this.Ascll++),
            choiceContent: null,
            isAnswer: 0,
            cause: null,
          },
          {
            sn: String.fromCharCode(this.Ascll++),
            choiceContent: null,
            isAnswer: 0,
            cause: null,
          },
          {
            sn: String.fromCharCode(this.Ascll++),
            choiceContent: null,
            isAnswer: 0,
            cause: null,
          },
          {
            sn: String.fromCharCode(this.Ascll++),
            choiceContent: null,
            isAnswer: 0,
            cause: null,
          },
        ];
      } else if (data == "变式描述") {
        this.form = {
          variantDescriptionValue: "1",
          caseTopicSerial: "", //变式编号
          id: null,
          label: "",
          value: "",
          description: "",
          stemContent: "",
          variantSubjectValue: "1", //题目类型--//1单选2多选
          difficulty: 1,
          score: 1,
          caseSign: "1", //真题还练习题
          caseTopic: "", //题干
          caseAnalysis: this.form.caseAnalysis, //解析
        };
        this.isvariant = 1;
      }
    },
    //新增题目选项
    addTopic () {
      if (this.topicData.length == 0) {
        this.Ascll = 65;
      }
      if (this.topicData.length > 0) {
        var Ascll = this.topicData[this.topicData.length - 1];
        if (Ascll.sn.charCodeAt() >= 65 && Ascll.sn.charCodeAt() <= 90) {
          this.Ascll = Ascll.sn.charCodeAt() + 1;
        }
      }

      this.topicData.push({
        sn: String.fromCharCode(this.Ascll++),
        choiceContent: null,
        isAnswer: 0,
        cause: null,
      });
    },
    //题目选项编辑
    handleEdit (index, row) {
      var obj = {};
      obj = JSON.parse(JSON.stringify(row)); //row 编辑的内容  cause
      this.topicDataContent = obj;
      this.topicIndex = index; //记录编辑内容的下标
      this.dialogVisible = true;
    },
    //题目选项删除
    handleDelete (index, row) {
      this.topicData.splice(index, 1);
      this.$message({
        message: "删除成功",
        type: "success",
      });
    },
    //删除该变式或者题目
    removeSaveDescription (node, data) {
      if (this.isvariant == 1) {
        //描述
        this.data[0].children.map((item, index) => {
          if (item.id == this.form.id) {
            this.$confirm("此操作将永久删除该描述, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.data[0].children.splice(index, 1);
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.isvariant = 0;
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消删除",
                });
              });
          }
        });
      } else if (this.isvariant == 2) {
        //变式
        this.data[1].children.map((item, index) => {
          if (item.id == this.form.id) {
            this.$confirm("此操作将永久删除该描述, 是否继续?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.data[1].children.splice(index, 1);
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.isvariant = 0;
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消删除",
                });
              });
          }
        });
      }
    },
    //选项移动
    optionMove (index, row, direction) {
      if (direction == "up") {
        if (index == 0) {
          this.$message.error("无法在向上移动了");
          return;
        }
        var data = [];
        data = JSON.parse(JSON.stringify(this.topicData));
        let meSn = row.sn;
        let sn = this.topicData[index - 1].sn;
        let bridge = JSON.parse(JSON.stringify(this.topicData[index - 1])); //保存的上一个全部内容
        data[index - 1] = row;
        data[index - 1].sn = sn;
        data[index] = bridge;
        data[index].sn = meSn;
        this.topicData = data;
      } else {
        if (this.topicData.length == index + 1) {
          this.$message.error("无法在向下移动了");
          return;
        }
        var data = [];
        data = JSON.parse(JSON.stringify(this.topicData));
        let meSn = row.sn;
        let sn = this.topicData[index + 1].sn;
        let bridge = JSON.parse(JSON.stringify(this.topicData[index + 1])); //保存的下一个全部内容
        data[index + 1] = row;
        data[index + 1].sn = sn;
        data[index] = bridge;
        data[index].sn = meSn;
        this.topicData = data;
      }
    },
    //关闭弹窗
    handleClose (done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.topicDataContent = {
            choiceContent: "",
            cause: "",
            sn: "",
          };
          done();
        })
        .catch((_) => { });
    },
    //判断选项是否有空值
    isOptionNull () {
      var a = false;
      var isAnswer = 0;
      this.IScaseTopic = false;
      if (this.topicData.length > 0) {
        this.topicData.map((item) => {
          if (item.choiceContent == null || item.choiceContent == "") {
            a = true;
          }
          if (this.form.variantSubjectValue == 2) {
            //多选
            if (item.isAnswer == 1) {
              isAnswer++;
            }
          }
          if (this.form.variantSubjectValue == 1) {
            //单选
            if (item.isAnswer == 1) {
              isAnswer++;
            }
          }
        });
      }
      if (a) {
        this.$message.error("选项内容不可有空");
        return a;
      }
      if (this.form.variantSubjectValue == 2) {
        if (!(isAnswer >= 2)) {
          this.$message.error("标准答案不可少于2个");
          a = true;
        }
        return a;
      }
      if (this.form.variantSubjectValue == 1) {
        if (!(isAnswer == 1)) {
          this.$message.error("标准答案可能为1个");
          a = true;
          return a;
        }
      }
      if (this.form.variantSubjectValue == "") {
        this.$message.error("请题目类型");
        a = true;
        return a;
      }

      if (this.form.caseTopicSerial == "") {
        this.$message.error("请输入题目名称");
        a = true;
        return a;
      }
      //  else if (this.form.caseTopic == "") {
      //   this.$message.error("请输入题干内容");

      //   a = true;
      //   return a;
      // }
      else if (this.topicData.length < 0) {
        this.$message.error("选项不可少于1个");
        a = true;
        return a;
      }
      return a;
    },
    //保存修改的内容选项
    dialogConfirm (data) {
      if (data == 1) {
      } else {
        //确认
        this.topicData[this.topicIndex].sn = this.topicDataContent.sn;
        this.topicData[this.topicIndex].choiceContent =
          this.$refs.addEditorSpaceChoiceContent.content;
        this.topicData[this.topicIndex].cause =
          this.$refs.addEditorSpaceCause.content;
      }

      this.dialogVisible = false;
      //清空.....弹窗中的内容
      this.topicDataContent = {
        choiceContent: "",
        cause: "",
        sn: "",
      };
    },
    // 拖拽成功完成时触发的事件
    handleDrop (draggingNode, dropNode, dropType, ev) { },
    allowDrop (draggingNode, dropNode, type) {
      // draggingNode 拖动的元素
      // dropNode 放下的元素
      if (this.isOpen === "one") {
        // 当放下的节点 等级也是1 我们才能拖动放下
        if (dropNode.level == 1) {
          return type !== "inner";
        } else {
          return false;
        }
      } else if (this.isOpen === "two") {
        // 当放下的节点 等级也是2 而且 他们到属于同层级  我们才能拖动放下
        if (dropNode.level == 2 && dropNode.parent === draggingNode.parent) {
          return type !== "inner";
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    // 拖动 选中的限制
    // 用来区分是 要拖动一级菜单，还是二级菜单，还是都不是 当 return true 才可以拖动
    allowDrag (draggingNode) {
      if (this.isOpen === "one") {
        return draggingNode.level === 1;
      } else if (this.isOpen === "two") {
        return draggingNode.level === 2;
      } else {
        return false;
      }
    },
    //提交
    clickSubmit () {
      this.$confirm("此操作将永久保存该变式, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.submitData(1);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消保存",
          });
        });
    },
    //返回
    clickReturn () {
      this.$confirm("此操作将不保存修改内容返回上一级, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$emit("go_back", 2);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    //预览变式
    clickPreview (e) {
      const a = this.$router.resolve({
        name: "Guangzhoupreview",
        query: {
          list: JSON.stringify(this.data),
          name: this.caseName,
          Treepreview: this.Treepreview,
        },
      });
      window.open(a.href, "_blank");
    },
  },
};
</script>
<style lang='less' scoped>
@import '../../../../style/k12SetQuestion/particulars.less';
@import '../style/addvariant.less';
/deep/.el-dialog {
  height: 80%;
  .el-dialog__body {
    height: 80%;
  }
}
</style>