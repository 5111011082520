<!-- 专题详情 -->
<template>
  <div class="box">
    <el-button
      type="primary"
      size="mini"
      icon="el-icon-back"
      @click="$emit('go_back', 1)"
      style="margin-bottom: 20px"
      >返回</el-button
    >
    <div class="del_box">
      <el-card style="width: 40%; margin-right: 40px" class="card_left">
        <div class="div_title">
          <span class="span_title">内容描述-{{ treeData.title }}</span>
          <el-button type="primary" size="mini" @click="details_operation(0)"
            >编辑描述</el-button
          >
        </div>
        <div class="div_content">
          <el-scrollbar style="height: 100%">
            <p v-html="description"></p>
          </el-scrollbar>
        </div>
        <div class="div_title">
          <span class="span_title">知识点</span>
        </div>
        <div class="div_tree">
          <el-scrollbar style="height: 100%">
            <el-tree
              :data="data"
              :props="defaultProps"
              @node-click="handleNodeClick"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span class="tree-button" v-if="node.level == 1">
                  <el-button
                    type="text"
                    size="mini"
                    @click.stop="treeOperation(node, data, 1)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    size="mini"
                    @click.stop="treeOperation(node, data, 2)"
                    :disabled="data.publishState == 1"
                    >删除</el-button
                  >
                </span>
              </span>
            </el-tree>
          </el-scrollbar>
        </div>
      </el-card>
      <el-card style="width: 70%">
        <el-button
          type="primary"
          size="mini"
          class="add_variant"
          @click="details_operation(3)"
          >新增变式</el-button
        >
        <el-scrollbar style="height: 90%">
          <el-table :data="tableData" style="width: 100%">
            <el-table-column
              prop="caseSerial"
              label="变式编号"
            ></el-table-column>
            <el-table-column prop="caseName" label="变式名称"></el-table-column>
            <el-table-column label="操作" width="250">
              <template slot-scope="scope">
                <el-button size="mini" @click="details_operation(1, scope.row)"
                  >进入</el-button
                >
                <el-button size="mini" @click="details_operation(4, scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="details_operation(5, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-scrollbar>
        <el-pagination
          background
          layout="prev, pager, next, sizes, jumper"
          :total="page.total"
          :current-page="page.current"
          :page-size="page.pagesize"
          :page-sizes="[10, 20, 30, 40, 50]"
          @current-change="columnData"
          @size-change="handleSizeChange"
        ></el-pagination>
      </el-card>
    </div>
    <dialogTree
      :showDialog="showDialog"
      @number="Refresh"
      :edit_knowledge="edit_knowledge"
    ></dialogTree>
    <dialogVariant
      :show_dialogVariant="show_dialogVariant"
      @show="refreshList"
      :variant="variant"
      :knowledgeNodes="data"
      :edit_list_row="edit_list_row"
    ></dialogVariant>
    <el-dialog :title="K_title" :visible.sync="dialogTableVisible" width="30%">
      <div
        v-for="(item, index) in dio_arr"
        :key="index"
        class="dia_div"
        style="height: 300px"
      >
        <el-scrollbar style="height: 100%">
          <span v-html="item.description" class="dia_span"></span>
        </el-scrollbar>
        <el-divider v-if="dio_arr.length > 1"></el-divider>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getKnowledgeByThemeId,
  getThemeCaseNamePage,
  getKnowledgeDescById,
  getThemeDescByThemeId,
  themeCaseDelete,
} from "../../../../api/api";
import dialogTree from "./dialogTree.vue";
import dialogVariant from "./dialogVariant.vue";
export default {
  props: {
    treeData: {
      type: Object,
    },
    del_fin: {
      type: Number,
    },
  },
  data() {
    return {
      page: {
        total: 0,
        current: 1,
        pagesize: 10,
      }, //分页
      tableData: [], //表格数据
      showDialog: -1, //弹框显示
      edit_knowledge: {}, //编辑的知识点内容
      description: null,
      data: [],
      variant: null, //变式信息
      show_dialogVariant: -1, //是否显示新建变式弹框
      defaultProps: {
        children: "knowledgeNodes",
        label: "title",
      },
      edit_list_row: {}, //编辑的变式
      K_title: "", //知识点详情
      dialogTableVisible: false,
      dio_arr: [],
    };
  },
  watch: {
    del_fin(val, old) {
      if (val !== old) {
        if (val == 1) {
          this.loadTree();
        }
      }
    },
  },
  components: {
    dialogTree,
    dialogVariant,
  },
  mounted() {
    this.loadTree();
    this.loadDescribe();
    this.loadCase();
  },
  methods: {
    // 点击知识点查看详情
    handleNodeClick(data, node) {
      if (node.level == 2) {
        getKnowledgeDescById({ id: data.id }).then((res) => {
          if (res.code == 0) {
            this.dio_arr = res.data.descriptions;
          } else {
            this.$message.error(res.message);
          }
        });
        this.dialogTableVisible = true;
        this.K_title = data.title;
      }
    },
    // 刷新变式列表
    refreshList() {
      this.show_dialogVariant = -1;
      this.loadCase();
    },
    // 加载变式列表
    loadCase() {
      let params = {
        pageNum: this.page.current,
        pageSize: this.page.pagesize,
        query: {
          themeId: this.treeData.id,
        },
      };
      getThemeCaseNamePage(params).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.list;
          this.page.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 刷新描述
    Refresh() {
      this.edit_knowledge = {};
      this.showDialog = -1;
      this.loadDescribe();
    },
    // 加载描述
    loadDescribe() {
      getThemeDescByThemeId({ themeId: this.treeData.id }).then((res) => {
        if (res.code == 0) {
          this.treeData.description = res.data.description;
          this.description = res.data.description;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 加载树状数据
    loadTree() {
      getKnowledgeByThemeId({ themeId: this.treeData.id }).then((res) => {
        if (res.code == 0) {
          this.data = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 树的操作
    treeOperation(node, val, type) {
      // type  1 编辑  2 删除
      if (type == 1) {
        this.$emit("go_question", val);
      } else if (type == 2) {
        this.$emit("del_knowledge", val.id);
      }
    },
    // 详情页操作
    details_operation(type, val) {
      // type  0 编辑描述  1 新增题目  2 删除  3 新增变式  4 编辑变式  5 删除变式
      if (type == 0) {
        this.showDialog = 6;
        this.edit_knowledge = this.treeData;
      } else if (type == 1) {
        this.$emit("caseData", val);
        this.$emit("go_back", 5);
      } else if (type == 2) {
        this.$emit("del_knowledge", val.id);
      } else if (type == 3 || type == 4) {
        if (type == 4) {
          this.edit_list_row = val;
        }
        this.variant = this.treeData;
        type == 3
          ? (this.show_dialogVariant = 0)
          : (this.show_dialogVariant = 1);
      } else if (type == 5) {
        this.$confirm("此操作将永久删除该变式, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            themeCaseDelete({ id: val.id }).then((res) => {
              console.log(res);
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.loadCase();
              } else {
                this.$message.error(res.message);
              }
            });
          })
          .catch(() => {});
      }
    },
    // 班级分页
    columnData(val) {
      this.page.current = val;
      this.loadCase();
    },
    // 选择分页
    handleSizeChange(val) {
      this.page.pagesize = val;
      this.loadCase();
    },
  },
};
</script>
<style lang='less' scoped>
@import "../style/treeDetails.less";
</style>
