<!-- 导入题目 -->
<template>
  <div>
    <el-card style="position: relative">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="110px"
        class="demo-ruleForm"
      >
        <el-divider content-position="center">{{ title }}</el-divider>
        <el-form-item label="知识点名称：" prop="name">
          <el-input v-model="ruleForm.name" size="mini" maxlength="200" show-word-limit></el-input>
        </el-form-item>
        <el-button type="primary" size="mini" style="margin:0 0 20px 110px" @click="add_node">添加知识节点</el-button>
        <div class="form_border" v-for="(item,index) in node_arr" :key="index">
          <div style="position: relative;padding-bottom:40px">
            <el-form-item label="节点名称：">
              <el-input v-model="item.title" size="mini" maxlength="200" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="知识点：">
              <div v-for="(des_item,des_index) in item.descriptions" :key="des_index">
                <!-- class="form_border1" -->
                <div>
                  <mceEditor
                    v-model="des_item.description"
                    :ref="`remarks${des_index}`"
                    :height="400"
                  />
                </div>
                <!-- <span>
                  <el-button
                    type="danger"
                    size="mini"
                    @click="del_des(item,des_index)"
                    v-if="item.descriptions.length>1"
                  >删除</el-button>
                </span>-->
              </div>
            </el-form-item>
            <!-- <el-button
              type="primary"
              size="mini"
              class="item_button"
              @click="click_des_item(item)"
            >添加内容</el-button>-->
          </div>
          <span>
            <el-button type="danger" size="mini" @click="del_node(item,index)">删除该节点</el-button>
          </span>
        </div>
      </el-form>
      <div class="foot_button">
        <el-button @click="go_back" size="small">取消</el-button>
        <el-button
          type="primary"
          @click="new_subject('ruleForm')"
          size="small"
        >{{ page_type == 1 ? "添加" : "编辑" }}</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import mceEditor from "../../../materialGather/k12SetQuestion/mceEditor.vue";
import { knowledgeAdd, getKnowledgeById, knowledgeModify } from '../../../../api/api'
export default {
  props: {
    type: {
      type: Number,
    },
    page_know: {
      type: Object
    },
    treeData: {
      type: Object
    }
  },
  data () {
    return {
      ruleForm: {
        name: "",
        remarks: "",
        topic_value: "", //题目内容
        analysis: "", //题目解析
        IffroMaddEditor: "", //富文本输入内容
      },
      rules: {
        name: [{ required: true, message: "请输入题目名称", trigger: "blur" }],
        remarks: [
          { required: true, message: "请输入题目备注", trigger: "blur" },
        ],
        IffroMaddEditor: [
          { required: true, message: "请输入题目内容", trigger: "blur" },
        ],
      },
      node_arr: [],//节点数据
      page_type: 1, //1 添加知识点  0 编辑知识点
      title: "知识点", //标题
      del_arr: [],//删除的知识节点
    };
  },
  watch: {
    type (val, old) {
      if (val !== old) {
        this.node_arr = []
        if (val == 1) {
          this.node_arr = [{
            title: '',
            descriptions: [{
              description: ''
            }]
          }]
        }
        this.page_type = val
        if (this.$refs['ruleForm']) {
          this.$refs['ruleForm'].resetFields();
        }
        if (this.page_type == 0) {
          this.loadKnowledge()
        }
      }
    },
  },
  components: {
    mceEditor,
  },
  methods: {
    // 添加知识点
    click_des_item (val) {
      val.descriptions.push({
        description: ''
      })
      console.log(val)
    },
    // 加载知识点
    loadKnowledge () {
      getKnowledgeById({ id: this.page_know.id }).then(res => {
        if (res.code == 0) {
          this.ruleForm.name = res.data.title
          res.data.knowledgeNodes.forEach(item => {
            let obj = {
              title: item.title,
              id: item.id,
              descriptions: item.descriptions
            }
            this.node_arr.push(obj)
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 添加知识节点
    add_node () {
      this.node_arr.push({
        title: '',
        descriptions: [{
          description: ''
        }]
      })
    },
    // 删除知识点
    del_des (val, index) {
      val.descriptions.splice(index, 1)
    },
    // 删除节点
    del_node (val, index) {
      this.del_arr.push(val.id)
      this.node_arr.splice(index, 1)
    },
    // 确定新增
    new_subject (formName) {
      let guard = true
      this.node_arr.forEach(item => {
        item.descriptions.forEach(des_item => {
          if (item.title && des_item.description && guard == true) {
          } else {
            guard = false
          }
        })
      })
      this.$refs[formName].validate((valid) => {
        if (valid && guard) {
          if (this.page_type == 1) {
            let params = {
              "knowledgeNodes": this.node_arr,
              "themeId": this.treeData.id,
              "title": this.ruleForm.name
            }
            // console.log(params)
            knowledgeAdd(params).then(res => {
              if (res.code == 0) {
                this.$message.success('添加成功！')
                this.$refs[formName].resetFields();
                this.node_arr = [{
                  title: '',
                  descriptions: [{
                    description: ''
                  }]
                }]
              } else {
                this.$message.error(res.message)
              }
            })
          } else if (this.page_type == 0) {
            let params = {
              "knowledgeNodes": this.node_arr,
              "id": this.page_know.id,
              "title": this.ruleForm.name,
              "removeIds": this.del_arr,
              "themeId": this.treeData.id
            }
            knowledgeModify(params).then(res => {
              if (res.code == 0) {
                this.$message.success('编辑成功！')
                this.go_back()
              } else {
                this.$message.error(res.message)
              }
            })
          }

        } else {
          this.$message.warning('请完善信息！')
          return false;
        }
      });
    },
    // 返回上一级
    go_back () {
      if (this.page_know.path == 'index') {
        this.$emit('go_back', 1)
      } else {
        this.$emit('go_back', this.page_type)
      }
    },
    // 二次取消
    handleClose (done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },
  },
};
</script>
<style lang='less' scoped>
@import '../style/importQuestion.less';
.item_button {
  position: absolute;
  right: 0;
}
.form_border1 {
  display: flex;
  border: 1px solid rgb(194, 194, 194);
  margin-bottom: 20px;
}
</style>
