<!-- 新增、编辑变式 -->
<template>
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="变式编号" prop="id">
          <el-input v-model="ruleForm.id" size="mini" type="number"></el-input>
        </el-form-item>
        <el-form-item label="变式名称" prop="name">
          <el-input v-model="ruleForm.name" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="关联知识点" prop="knowledge">
          <el-cascader
            :options="variantType"
            :show-all-levels="false"
            :props="props"
            collapse-tags
            v-model="ruleForm.knowledge"
            size="mini"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false;$emit('show',-1)">取 消</el-button>
        <el-button type="primary" @click="add_node('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { themeCaseAddName, themeCaseUpdateName } from '../../../../api/api'
export default {
  props: {
    show_dialogVariant: {
      type: Number
    },
    variant: {
      type: Object
    },
    knowledgeNodes: {
      type: Array
    },
    edit_list_row: {
      type: Object
    }
  },
  data () {
    return {
      dialogTitle: '创建变式',
      dialogVisible: false,
      ruleForm: {
        name: '',
        id: '',
        knowledge: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入变式名称', trigger: 'blur' },
        ],
        id: [
          { required: true, message: '请选择变式编号', trigger: 'blur' }
        ],
        knowledge: [
          { required: true, message: '请关联知识点', trigger: 'blur' },
        ],
      },
      variantType: [{
        value: 1,
        label: '知识点1',
        children: [{
          value: 2,
          label: '知识点1-1',
        }, {
          value: 3,
          label: '知识点1-2',
        }]
      }, {
        value: 4,
        label: '知识点2',
        children: [{
          value: 5,
          label: '知识点2-1',
        }, {
          value: 6,
          label: '知识点2-2',
        }]
      }],//级联数据
      props: {
        multiple: false,
        value: 'id',
        label: 'title',
        children: 'knowledgeNodes',
      },
      type: -1
    }
  },
  watch: {
    show_dialogVariant (val, old) {
      if (val !== old) {
        this.type = val
        if (this.$refs['ruleForm']) {
          this.$refs['ruleForm'].resetFields();
        }
        this.ruleForm = {
          name: '',
          id: '',
          knowledge: ''
        }
        if (val == 0) {
          this.dialogVisible = true
          this.dialogTitle = '创建变式'
        } else if (val == 1) {
          this.dialogVisible = true
          this.dialogTitle = '编辑变式'
        } else {
          this.dialogVisible = false
        }

      }
    },
    knowledgeNodes (val, old) {
      if (val !== old) {
        this.variantType = val
      }
    },
    edit_list_row (val, old) {
      if (val !== old) {
        let arr = []
        let know_arr = val.knowledgeId.split(',')
        this.variantType.forEach(item => {
          item.knowledgeNodes.forEach(know_item => {
            let know_details = know_arr.find(find_item => { return find_item == know_item.id })
            if (know_details) {
              arr.push(item.id)
              arr.push(know_item.id)
            }
          })
        })
        this.ruleForm.name = val.caseName
        this.ruleForm.id = val.caseSerial
        this.ruleForm.knowledge = arr
      }
    },
  },
  components: {},

  methods: {
    // 确认新增变式
    add_node (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let arr = this.ruleForm.knowledge.join(',').split(',')
          let knowledgeId_arr = [...new Set(arr)]
          if (this.type == 0) {
            let params = {
              "themeId": this.variant.id,//专题id
              "difficulty": 1,//难度
              "caseSerial": this.ruleForm.id,//变式编号
              "caseName": this.ruleForm.name,//变式名称
              "knowledgeId": knowledgeId_arr.join(','),//知识点
              "correctKnowledgeId": this.ruleForm.knowledge[1]
            }
            themeCaseAddName(params).then(res => {
              if (res.code == 0) {
                this.$message.success('添加成功')
                this.$emit('show', -1)
              } else {
                this.$message.error(res.message)
              }
            })
          } else if (this.type == 1) {
            let params = {
              "id": this.edit_list_row.id,//变式id
              "difficulty": 1,//难度
              "caseSerial": this.ruleForm.id,//变式编号
              "caseName": this.ruleForm.name,//变式名称
              "knowledgeId": knowledgeId_arr.join(','),//知识点
            }
            themeCaseUpdateName(params).then(res => {
              if (res.code == 0) {
                this.$message.success('编辑成功')
                this.$emit('show', -1)
              } else {
                this.$message.error(res.message)
              }
            })
          }

        } else {
          return false;
        }
      });
    },
    node () { },
    // 设置点击文本选中
    elCascaderOnlick () {
      setTimeout(function () {
        document.querySelectorAll(".el-cascader-menu").forEach((el, index) => {
          if (index == 1) {
            el.querySelectorAll(".el-cascader-node__label").forEach((el1) => {
              el1.onclick = function () {
                this.previousElementSibling.click();
              };
            });
          }
        });
      }, 100);
    },
    // 关闭二次确认
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.$emit('show', -1)
          done();
        })
        .catch(_ => { });
    }
  }
}

</script>
<style lang='less' scoped>
</style>
