<!-- 树状图弹框 -->
<template>
  <div>
    <!-- 编辑、新增弹框 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="科目" prop="subject" v-if="type ==5">
          <el-select v-model="ruleForm.subject" placeholder="请选择科目" size="mini">
            <el-option
              v-for="item in subjectOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="节点名称" prop="name" v-if="type ==0||type==1||type==5">
          <el-input v-model="ruleForm.name" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="描述" v-if="type ==0||type==1||type==5||type==6">
          <mceEditor v-model="ruleForm.describe"></mceEditor>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false;$emit('number',-1)">取 消</el-button>
        <el-button type="primary" @click="add_node('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { themeAdd, themeModify, themeDelete, getThemeDescByThemeId } from '../../../../api/api'
import mceEditor from "../../../materialGather/k12SetQuestion/mceEditor.vue";
export default {
  props: {
    showDialog: {
      type: Number
    },
    subjectOptions: {
      type: Array
    },
    treeNode: {
      type: Object
    },
    edit_knowledge: {
      type: Object
    }
  },
  data () {
    return {
      ruleForm: {//表单内容
        name: '',
        subject: null,
        describe: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入节点名称', trigger: 'blur' },
        ],
        subject: [
          { required: true, message: '请选择科目', trigger: 'change' }
        ],
        describe: [
          { required: true, message: '请输入描述', trigger: 'blur' },
        ],
      },
      dialogTitle: '',//弹框标题
      dialogVisible: false,//控制弹框的显示隐藏
      subjectOption: [],//学科
      type: null,//操作类型  0 添加子节点  1 编辑节点  5 添加根节点  6 编辑知识点(详情页)  7 添加知识点  8 编辑知识点
    }
  },

  components: {
    mceEditor
  },
  watch: {
    showDialog (val, old) {
      if (val !== old) {
        this.type = val
        if (this.$refs['ruleForm']) {
          this.ruleForm = {
            name: '',
            subject: null,
            describe: ''
          }
        }
        if (val == 0) {
          this.dialogTitle = '添加子节点'
          this.dialogVisible = true
        } else if (val == 1) {
          this.ruleForm.name = this.treeNode.title
          this.loadDescribe()
          this.dialogTitle = '修改节点'
          this.dialogVisible = true
        } else if (val == 5) {
          this.dialogTitle = '添加根节点'
          this.dialogVisible = true
        } else if (val == 6) {
          this.dialogTitle = '编辑描述'
          this.dialogVisible = true
        } else {
          this.dialogVisible = false
        }
      }
    },
    edit_knowledge (val, old) {//详情页传入的描述
      if (val !== old) {
        this.ruleForm.describe = val.description
      }
    }
  },
  methods: {
    // 加载描述
    loadDescribe () {
      getThemeDescByThemeId({ themeId: this.treeNode.id }).then(res => {
        if (res.code == 0) {
          this.ruleForm.describe = res.data.description
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 添加节点
    add_node (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let param = {}
          if (this.type == 0) {
            param = {
              "description": this.ruleForm.describe,
              "parentId": this.treeNode.id,
              "subjectId": this.treeNode.subjectId,
              "title": this.ruleForm.name
            }
          } else if (this.type == 1 || this.type == 6) {
            param = {
              "id": this.type == 1 ? this.treeNode.id : this.edit_knowledge.id,
              "title": this.type == 1 ? this.ruleForm.name : this.edit_knowledge.title,
              "description": this.ruleForm.describe
            }
          } else if (this.type == 5) {
            param = {
              "description": this.ruleForm.describe,
              "parentId": 0,
              "subjectId": this.ruleForm.subject,
              "title": this.ruleForm.name
            }
          }
          if (this.type == 1 || this.type == 6) {
            themeModify(param).then(res => {
              if (res.code == 0) {
                this.$message.success('编辑成功！')
                this.$emit('number', -2)
              } else {
                this.$message.error(res.message)
              }
            })
          } else if (this.type == 0 || this.type == 5) {
            themeAdd(param).then(res => {
              if (res.code == 0) {
                this.$message.success('添加成功！')
                this.$emit('number', -2)
              } else {
                this.$message.error(res.message)
              }
            })
          }
        } else {
          return false;
        }
      });

    },
    // 关闭二次确认
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.$emit('number', -1)
          done();
        })
        .catch(_ => { });
    }
  }
}

</script>
<style lang='less' scoped>
.el-dialog__body .el-form-item {
  .el-select {
    width: 100%;
  }
}
</style>
