import { render, staticRenderFns } from "./treeDetails.vue?vue&type=template&id=705b0200&scoped=true&"
import script from "./treeDetails.vue?vue&type=script&lang=js&"
export * from "./treeDetails.vue?vue&type=script&lang=js&"
import style0 from "./treeDetails.vue?vue&type=style&index=0&id=705b0200&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "705b0200",
  null
  
)

export default component.exports