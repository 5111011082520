<!-- 知识点列表 -->
<template>
  <div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose"
    >
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background: '#F3F7FF',color: '#0B1B31',fontSize: '14px',fontWeight: '500',}"
      >
        <el-table-column prop="id" label="ID" width="100"></el-table-column>
        <el-table-column prop="title" label="知识点"></el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button size="mini" @click="operation(0,scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="operation(1,scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <dialogTree :showDialog="showDialog" @number="showDialog= -1"></dialogTree>
  </div>
</template>

<script>
import dialogTree from './dialogTree.vue'
import { getKnowledgeByThemeId } from '../../../../api/api'
export default {
  props: {
    showTable: {
      type: Boolean
    },
    treeData: {
      type: Object
    },
    del_fin: {
      type: Number
    }
  },
  data () {
    return {
      dialogTitle: '知识点列表',
      dialogVisible: false,
      tableData: [],
      showDialog: -1
    }
  },
  watch: {
    showTable (val, old) {
      if (val !== old) {
        this.dialogVisible = val
        this.loadTree()
      }
    },
    del_fin (val, old) {
      if (val !== old) {
        if (val == 1) {
          this.loadTree()
        }
      }
    }
  },
  components: {
    dialogTree
  },
  methods: {
    // 加载树状数据
    loadTree () {
      getKnowledgeByThemeId({ themeId: this.treeData.id }).then(res => {
        if (res.code == 0) {
          this.tableData = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    operation (type, val) {
      // type 0 编辑  1 删除
      if (type == 0) {
        val['path'] = 'index'
        this.$emit('go_question', val)
        // this.dialogVisible = false
      } else if (type == 1) {
        this.$emit('del_knowledge', val.id)
      }
    },
    // 关闭二次确认
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          this.$emit('unshow', false)
          done();
        })
        .catch(_ => { });
    }
  }
}

</script>
<style lang='less' scoped>
</style>
